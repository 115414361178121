<template>
	<div style="width: 100%;height: auto;" class="fy">
		<div class="page-bottom-color">
			<div class="page-bottom-top-block">
				<div class="page-bottom-place w10">
					<div class="w2 pl1 logo">
						<img
							src="/images/LOGO_foot.png"
							class="bottom-logo"
							alt=""
						>
					</div>
					<div class="fy w2 mx2 about-us">
						<span class="mb2 font-bold">{{ $t('bottompic.aboutUs') }}</span>
						<a href="https://www.picoxr.com/th" target="_blank" class="my2 white font-m pointer">{{ $t('bottompic.aboutPICO') }}</a>
					</div>
					<div class="fy w2 mx2 help-center">
						<span class="mb2 font-bold">{{ $t('bottompic.helpCenter') }}</span>
						<span class="my2 font-m pointer">
							{{ $t('bottompic.warrantyPolicy') }}
						</span>
						<span class="my2 font-m pointer">{{ $t('bottompic.privacyPolicy') }}</span>
					</div>
					<div class="fy-box w2 mx2 follow-us">
						<span class="mb2 font-bold">{{ $t('bottompic.followUs') }}</span>
						<div class="fx">
							<img src="/images/line.png" class="mt2 mx1 pointer social-icon" alt=""
								@click="open('https://manager.line.biz/account/@396gahlf')"
							>
							<img src="/images/facebook_new.png" class="mt2 mx1 pointer social-icon" alt=""
								@click="open('https://www.facebook.com/groups/660041976135970')"
							>
							<img src="/images/tiktok.png" class="mt2 mx1 pointer social-icon" alt=""
								@click="open('https://www.tiktok.com/@picoxr_th')"
							>
						</div>
					</div>
				</div>
				<div class="font-s mb2 copy-right" style="color: rgba(255,255,255,0.7)">Copyright@2022 Pando Care</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PC',
		computed: {},
		data() {
			return {
				defaultLang: '',
				defaultImg: '',
				logoType: true,
				lang: this.$i18n.locale,
			};
		},
		created() {
			this.defaultLang = this.$i18n.messages[this.$i18n.locale]._lang;

			if (this.$i18n.locale === 'en-US') {
				this.defaultImg = '/images/english.png';
			} else if (this.$i18n.locale === 'th-THA') {
				this.defaultImg = '/images/Flag_of_Thailand.svg';
			}
		},
		computed: {},
		methods: {
			open(url){
				window.open(url)
			}
		},
	};
</script>

<style scoped>
	.page-bottom-color {
		background: #202124;
		color: white;
	}
	.page-bottom-place {
		display: flex;
		display: -webkit-flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: top;
		margin: 50px 0;
	}
	.page-bottom-top-block {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
	}
	.social-icon {
		max-width: 40px;
		width: 28%;
	}
	@media (min-width: 500px) and (max-width: 1190px) {
		.page-bottom-top-block {
			margin: 0 30px;
		}
		.page-bottom-place {
			margin: 50px 0;
			width: 100%;
		}
		.bottom-logo {
			width: 90%;
		}
		.copy-right {
			display: none;
		}
  }
	@media (max-width: 500px) {
		.page-bottom-place {
			display: flex;
			display: -webkit-flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 30px 0;
		}
		.bottom-logo {
			width: 100%;
			padding-bottom: 30px;
		}
		.follow-us {
			width: 50%;
		}
		.about-us, .help-center {
			display: none;
		}
		.copy-right {
			display: block;
		}
  }
	@media (min-width: 1190px) {
		.page-bottom-top-block {
			width: 1190px;
		}
		.bottom-logo {
			width: 200px;
		}
		.copy-right {
			display: none;
		}
	}
	@media (width: 1190px) {
		.page-bottom-top-block {
			margin: 0;
		}
	}
</style>
