<template>
  <img v-if="advert.image" :src="advert.image" @click="advertJump()" alt="">
  <img v-else :src="defaultImg"/>
</template>

<script>
	import mixinJumpModule from '@/mixins/jump-module';

  export default {
    mixins: [mixinJumpModule],
    props: {
      type: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        advert: {},
        defaultImg: '',
      }
    },
    created(){
      if(this.type){
        if(this.type == 'LOGIN'){
          this.defaultImg = '/images/LOGIN_default.png'
        }
        else if(this.type == 'HOMEPAGE'){
          this.defaultImg = '/images/HOME_default.png'
        }
        else if(this.type == 'PRODUCT' || this.type == 'PRODUCTLIST' || this.type == 'COURSE' || this.type == 'REGISTER'){
          this.defaultImg = '/images/banner_default.png'
        }
        else if (this.type == 'CONTACT') {
          this.defaultImg = '/images/CONTACT_default.png';
          return;
        }
        this.$axios({
          url:'/v1/pico/advert/query',
          method: 'get',
          params: {
            advertType: this.type,
          }
        }).then((res) => {
          let advert = res.list.filter(item => item.status == 'Y')
          if(advert.length !== 0){
            this.advert = advert[0]
          }
        })
      }
    },
    methods: {
      advertJump(){
        if(this.advert.jumpType){
          this.jumpHandle(this.advert, false)
        }
      },
    },
  }
</script>

<style>

</style>