<template>
	<div class="fy content">
		<div class="pic">
			<!-- <img class="pictop" :src="image" width="100%" height="auto"/> -->
			<advert class="pictop" type="HOMEPAGE" width="100%" height="auto"></advert>
		</div>
		<div class="font-bold mb7 mt10 center" style="font-size: 22px">
			{{ $t('picoCare') }}
		</div>
		<div class="fx-between-box mb10 anchor">
			<div class="left" @click="goToRegisterPage">
				<img src="/images/register-ad.png" width="100%" height="100%" />
			</div>
			<div class="right" @click="goToProductPage">
				<img src="/images/use product-ad.png" width="100%" height="100%" />
			</div>
			<!-- <div class="abs">
                <img src="/images/facebook-messager.png">
            </div> -->
		</div>
	</div>
</template>
<script>
	import { cookieGet, cookieSet } from '@/common/cookie';
	import advert from '@/components/advert';

	export default {
		components: { advert },
		data() {
			return {
				image: '',
			};
		},
		methods: {
			goToRegisterPage() {
				if (cookieGet('token')) {
					this.$router.push('/picoRegister');
				} else {
					this.$router.push('/login');
				}
			},
			goToProductPage() {
				if (cookieGet('token')) {
					this.$router.push('/picoCheck');
				} else {
					this.$router.push('/login');
				}
			},
		},
	};
</script>
<style scoped>
	/* .abs{
      position: absolute;
      top:155px;
      right:-135px;
    } */
	.left {
		width: 48%;
	}
	.right {
		width: 48%;
	}
	@media screen and (max-width: 500px) {
		.content {
			width: 75%;
			margin: 0 auto;
			/* padding: 0 10px; */
			min-height: 0px;
		}
		.center {
			max-width: 450px;
		}
		.fx-between-box {
			display: flex;
			display: -webkit-flex;
			flex-direction: column;
			text-align: center;
		}
		.pic {
			width: 100%;
		}
		.left {
			width: 100%;
		}
		.right {
			width: 100%;
		}
	}
	@media (min-width: 501px) and (max-width: 730px) {
		.content {
			width: 100%;
			min-height: 0px;
		}
	}
	@media (min-width: 731px) and (max-width: 1189px) {
		.content {
			width: 100%;
			min-height: 0px;
		}
		.pic {
			width: 90%;
		}
		.pictop {
			width: 100%;
		}
	}
	@media (min-width: 1190px) {
		.content {
			max-width: 1190px;
		}
		.pictop {
			width: 1190px;
			height: 550px;
		}
	}
</style>
