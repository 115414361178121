<template>
	<div class="register fy-box">
		<div class="register-advert">
			<!-- <img :src="image" width="100%" height="auto"/> -->
			<advert type="REGISTER" width="100%" height="auto"></advert>
		</div>
		<div class="fy f-grow register-form">
			<el-form :model="registerForm" :rules="formRules" ref="registerForm" label-width="240px">
				<div class="font-xxl font-bold form-title">{{ $t('register.accountInfo') }}</div>
				<el-form-item :label="'1. ' + $t('register.username')" prop="username">
					<el-input v-model="registerForm.username"></el-input>
				</el-form-item>
				<el-form-item :label="'2. ' + $t('register.password')" prop="password">
					<el-input v-model="registerForm.password" type="password"></el-input>
				</el-form-item>
				<el-form-item :label="'3. ' + $t('register.confirmPassword')" prop="repassward">
					<el-input v-model="registerForm.repassward" type="password"></el-input>
				</el-form-item>
				<div class="pt6"></div>
				<div class="font-xxl font-bold form-title">{{ $t('register.personalInfo') }}</div>
				<el-form-item :label="'1. ' + $t('register.firstname')" prop="firstName">
					<el-input v-model="registerForm.firstName"></el-input>
				</el-form-item>
				<el-form-item :label="'2. ' + $t('register.lastname')" prop="lastName">
					<el-input v-model="registerForm.lastName"></el-input>
				</el-form-item>
				<el-form-item :label="'3. ' + $t('register.nickname')" prop="nickName">
					<el-input v-model="registerForm.nickName"></el-input>
				</el-form-item>
				<el-form-item :label="'4. ' + $t('register.gender')" prop="gender">
					<el-select :popper-append-to-body="false" style="width: 100%" v-model="registerForm.gender" :placeholder="$t('register.gender')">
						<el-option key="gender_Female" :label="$t('register.gender_Female')" value="1"></el-option>
						<el-option key="gender_Male" :label="$t('register.gender_Male')" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="'5. ' + $t('register.birth')" prop="birthday">
					<el-date-picker
						style="width: 100%"
						v-model="registerForm.birthday"
						type="date"
						:picker-options="pickerOptions"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item :label="'6. ' + $t('register.mobile')" prop="mobile">
					<el-input v-model="registerForm.mobile"></el-input>
				</el-form-item>
				<el-form-item :label="'7. ' + $t('register.email')" prop="email">
					<el-input v-model="registerForm.email"></el-input>
				</el-form-item>
				<el-form-item :label="'8.' + $t('register.address')" prop="provinceId">
					<div style="color: black">{{ $t('register.province') }}</div>
					<el-select :popper-append-to-body="false" style="width: 100%" v-model="registerForm.provinceId" @change="renderCity">
						<el-option v-for="item in listProv" :value="item.id" :label="item.name" :key="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="cityId">
					<div style="color: black">{{ $t('register.district') }}</div>
					<el-select :popper-append-to-body="false" style="width: 100%" v-model="registerForm.cityId" @change="renderCode">
						<el-option v-for="item in listCity" :value="item.id" :label="item.name" :key="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="zipCode">
					<div style="color: black">{{ $t('register.postalCode') }}</div>
					<el-input v-model="registerForm.zipCode" disabled> </el-input>
				</el-form-item>
				<el-form-item prop="detailAddress">
					<div style="color: black">{{ $t('register.detailAddress') }}</div>
					<el-input type="textarea" v-model="registerForm.detailAddress"> </el-input>
				</el-form-item>
				<div style="padding: 60px 0 50px 0">
					<el-checkbox v-model="registerForm.checked" class="fx" prop="checked">
						<div style="color: black; white-space: break-spaces">{{ $t('register.registerTip') }}</div>
					</el-checkbox>
				</div>

				<div class="fx-box">
					<el-button @click="submitForm" type="primary" :disabled="!registerForm.checked">{{ $t('register.submit') }}</el-button>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import advert from '@/components/advert';

	export default {
		name: 'register',
		components: { advert },
		data() {
			return {
				registerForm: {
					country: '+66',
					username: '',
					password: '',
					repassward: '',
					firstName: '',
					lastName: '',
					nickName: '',
					gender: '',
					birthday: '',
					mobile: '',
					email: '',
					provinceId: '',
					cityId: '',
					zipCode: '',
					detailAddress: '',
					checked: false,
				},
				listProv: [],
				listCity: [],
				image: '',

				formRules: {
					username: [
						{
							required: true,
							trigger: 'blur',
							message: this.$t('register.pleaseEnter'),
							/* vm: this,
							validator: (r,v,c) => {
								if (!v) {
									c(new Error(r.vm.$t('register.pleaseEnter')))
								} else {
									personal.isuserexist({username: v})
									.then(res => {
										if ('0' == res.code && 1 == res.data.exist) {
											c(new Error(r.vm.$t('register.accountExists')))
										} else {
											c()
										}
									})
								}
							}, */
						},
					],
					password: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					repassward: [
						{
							required: true,
							trigger: 'blur',
							vm: this,
							validator: (r, v, c) => {
								!v
									? c(new Error(r.vm.$t('register.pleaseEnter')))
									: r.vm.registerForm.password != v
									? c(new Error(r.vm.$t('register.passwordDifferent')))
									: c();
							},
						},
					],
					firstName: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					lastName: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					// nickName: [
					// 	{
					// 		required: true,
					// 		message: this.$t('register.pleaseEnter'),
					// 		trigger: 'blur',
					// 	},
					// ],
					// gender: [
					// 	{
					// 		required: true,
					// 		message: this.$t('register.pleaseEnter'),
					// 		trigger: 'blur',
					// 	},
					// ],
					birthday: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					mobile: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					email: [
						{
							required: true,
							trigger: 'blur',
							vm: this,
							validator: (r, v, c) => {
								if (!v) {
									c(new Error(r.vm.$t('register.pleaseEnter')));
								} else {
									const regEmail = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
									if (regEmail.test(v)) {
										// 合法的邮箱
										c();
									} else {
										// 不合法的邮箱
										c(new Error(r.vm.$t('register.emailFormatError')));
									}
								}
							},
						},
					],
					provinceId: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					cituId: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					zipCode: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					detailAddress: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
				},
				pickerOptions: {
					disabledDate(time) {
						//选择今天以及今天之前的日期
						return time.getTime() > Date.now();
					},
				},
			};
		},
		created() {
			this.getProvList();
		},
		methods: {
			submitForm() {
				this.$refs['registerForm'].validate((valid) => {
					if (valid) {
						console.log(this.registerForm.gender);
						this.$axios({
							url: '/v1/pico/user/register',
							method: 'post',
							data: {
								birthday: this.registerForm.birthday,
								cityId: this.registerForm.cityId,
								country: this.registerForm.country,
								detailAddress: this.registerForm.detailAddress,
								email: this.registerForm.email,
								firstName: this.registerForm.firstName,
								gender: this.registerForm.gender == '' ? '2' : this.registerForm.gender,
								lastName: this.registerForm.lastName,
								mobile: this.registerForm.mobile,
								nickName: this.registerForm.nickName,
								passwrd: this.registerForm.password,
								provinceId: this.registerForm.provinceId,
								repassward: this.registerForm.repassward,
								username: this.registerForm.username,
								zipCode: this.registerForm.zipCode,
								noQs: true,
							},
						}).then((res) => {
							this.$router.push('/registerSuccess');
						});
					}
				});
			},
			async getProvList() {
				await this.$axios({
					url: '/v1/address/queryprovince',
					method: 'get',
				}).then((res) => {
					this.listProv = res.list;
					// console.log(res);
				});
			},
			async renderCity() {
				this.listCity = [];
				this.registerForm.cityId = '';
				this.registerForm.zipCode = '';
				console.log(this.registerForm.provinceId);
				await this.$axios({
					url: '/v1/address/querycity',
					method: 'get',
					params: {
						privinceid: this.registerForm.provinceId,
					},
				}).then((res) => {
					// console.log('res',res);
					this.listCity = res.list;
				});
			},
			renderCode() {
				// console.log(this.registerForm.district);
				this.registerForm.zipCode = '';
				let item = this.listCity.filter((item) => item.id == this.registerForm.cityId);
				this.registerForm.zipCode = item[0].postCode;
				// this.registerForm.postalCode = this.registerForm.district
				// console.log(this.registerForm.zipCode);
			},
		},
	};
</script>

<style scoped>
	.register {
		padding: 60px 0 140px 0;
		width: 100%;
		max-width: 1190px;
	}
	.register-form {
		padding: 30px;
	}
	.register-advert {
		width: 100%;
	}
	@media (min-width: 650px) and (max-width: 1190px) {
		.register-form {
			width: 590px;
			padding: 55px 0 0 0;
		}
		.form-title {
			padding: 0 15px 30px;
		}
	}
	@media (max-width: 650px) {
		::v-deep .el-form-item {
			display: flex !important;
			flex-direction: column !important;
		}
		::v-deep .el-form-item__content {
			margin-left: 0 !important;
		}
		::v-deep .el-form-item__label {
			text-align: left !important;
		}
		.form-title {
			padding: 0;
		}
	}
	@media (max-width: 500px) {
		.register-advert {
			display: none;
		}
	}
	@media (min-width: 1190px) {
		.register-form {
			padding: 55px 350px 0 250px;
		}
		.form-title {
			padding: 0 15px 30px;
		}
	}
</style>

<style scoped>
	::v-deep .el-form-item__label {
		font-weight: bold !important;
		color: #333333;
		padding-right: 20px;
	}
	::v-deep .el-input__inner {
		border-radius: 5px !important;
	}
	::v-deep .el-button {
		border-radius: 1000px !important;
		font-size: 20px !important;
		width: 80%;
		max-width: 400px;
		height: 50px;
	}
</style>
