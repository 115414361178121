<!-- 顶层工具栏 -->
<template>
	<div class="root fx-list">
		<el-dropdown class="language" size="mini" trigger="click" @command="(command) => ($i18n.locale = command)">
			<span class="fx-list">
				<img class="mr1" :src="defaultImg" width="24px" height="16px" />
				<span>{{ defaultLang }}</span>
				<i class="el-icon-arrow-down el-icon--right"></i>
			</span>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item command="en-US">
					<span class="fx-list py1">
						<img class="mr1" src="/images/english.png" width="24px" height="16px" />
						<span>English</span>
					</span>
				</el-dropdown-item>
				<el-dropdown-item command="th-THA">
					<span class="fx-list py1">
						<img class="mr1" src="/images/Flag_of_Thailand.svg" width="24px" height="16px" />
						<span>ภาษาไทย</span>
					</span>
				</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
		<div class="f-grow"></div>
		<el-link v-if="!cookieGet('token')" class="mx4 login-or-register" :underline="false" @click="$router.push('/login')">{{ $t('toolbar.loginOrSignIn') }}</el-link>
		<el-dropdown class="user-info" v-if="cookieGet('token')" @command="userInfo" trigger="hover" placement="bottom">
			<div class="fx-list pointer" style="padding-right: 20px;">
				<img src="/images/user-img.png" width="28px" height="28px" style="margin:0 5px;" />
				{{userInfoData.username}}
			</div>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item :command="2"> <van-icon name="cluster-o" />{{ $t('toolbar.changePasswd') }}</el-dropdown-item>
				<el-dropdown-item :command="1"> <van-icon name="friends-o" />{{ $t('toolbar.logout') }}</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
		<el-dialog class="edit-password" :title="$t('toolbar.changePasswd')" v-if="dialogVisibleworrd" :visible.sync="dialogVisibleworrd">
			<div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
					<el-form-item :label="$t('toolbar.accountnum')">
						<el-input v-model="userInfoData.username" disabled></el-input>
					</el-form-item>
					<el-form-item :label="$t('toolbar.oldpassword')" prop="oldpasswd">
						<el-input v-model="ruleForm.oldpasswd" type="password"></el-input>
					</el-form-item>
					<el-form-item :label="$t('toolbar.newpassword')" prop="passwd">
						<el-input v-model="ruleForm.passwd" type="password"></el-input>
					</el-form-item>
					<el-form-item :label="$t('toolbar.confirmpassword')" prop="passwdcope">
						<el-input v-model="ruleForm.passwdcope" type="password"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="resetForm('ruleForm')">{{ $t('input_cancel') }}</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">{{ $t('input_confirm') }}</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import { cookieGet, cookieRemove } from '@/common/cookie';
	import { Toast } from 'vant'

	export default {
		name: 'PC',
		//这个注入是用于提供页眉布局信息的
		inject: ['setCacheParam'],
		props: {
			//路由参数,某些特定的路由会用到,结构由路由目标组件提供
			routeParam: {
				type: Object,
				default: () => {
					return {};
				},
			},
		},
		data() {
			return {
				defaultLang: '',
				defaultImg: '',
				userInfoData:{},
				// 修改密码
				dialogVisibleworrd: false,
				ruleForm: {
					passwd: '',
					oldpasswd: '',
					passwdcope: '',
				},
				rules: {
					passwd: [
						{
							required: true,
							message: this.$t('toolbar.pleaseenter'),
							trigger: 'blur',
						},
					],
					oldpasswd: [
						{
							required: true,
							message: this.$t('toolbar.pleaseenter'),
							trigger: 'blur',
						},
					],
					passwdcope: [
						{
							required: true,
							message: this.$t('toolbar.pleaseenter'),
							trigger: 'blur',
						},
					],
				},
			};
		},
		created() {
			this.defaultLang = this.$i18n.messages[this.$i18n.locale]._lang;
			if (this.$i18n.locale === 'en-US') {
				this.defaultImg = '/images/english.png';
			} else if (this.$i18n.locale === 'th-THA') {
				this.defaultImg = '/images/Flag_of_Thailand.svg';
			}
			if(cookieGet('user')){
				let userDataStr = cookieGet('user');
				let userInfo = JSON.parse(userDataStr);
				this.userInfoData.username = userInfo.username
			}
			
		},
		computed: {
		},
		methods: {
			cookieGet,
			userInfo(command){
				// 退出登录
				if(command == 1){
					this.logout()
				}
				// 修改密码
				else if(command == 2){
					this.dialogVisibleworrd = true;
				}
			},
			logout(){
				cookieRemove('token');
				cookieRemove('mobile')
				cookieRemove('email');
				cookieRemove('userName');
			  cookieRemove('user')
				// cookieRemove('userInfo')
				// 刷新
				this.$router.go(0)
			},
			submitForm(formName) {
				if (this.ruleForm.passwd !== this.ruleForm.passwdcope) {
					return this.$message(this.$t('register.passwordDifferent'));
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios({
							url:'/v1/user/modifypassword',
							method:'post',
							headers:{token:cookieGet("token")},
							params:{
              	password: this.ruleForm.oldpasswd,
								newPassword: this.ruleForm.passwd
							},
            }).then((res) => {
							if (res.code == '0') {
								Toast({
									message: cookieGet('language') == 'th-THA' ? 'ความสำเร็จ' : 'success',
									type: 'success',
									duration: 3 * 1000
								})
								this.dialogVisibleworrd = false;
								this.ruleForm.passwd = ''
                this.ruleForm.oldpasswd = ''
                this.ruleForm.passwdcope = ''
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogVisibleworrd = false;
			},
		},
	};
</script>

<style lang="less" scoped>
	.root {
		box-sizing: border-box;
		width: 1190px;
		height: 40px;
		color: white;
	}

	.avatar {
		width: 120px;
		height: 120px;
	}

	.infotag {
		width: 125px;
		height: 30px;
	}

	.info-style {
		&:hover {
			text-decoration: underline;
		}
	}

	::v-deep .el-dropdown {
		color: white;
	}
	::v-deep .el-link--inner:not(:hover) {
		color: white;
	}
	::v-deep .el-link.el-link--default:hover {
		color: white;
		text-decoration: underline;
	}
	.edit-password {
		::v-deep .el-dialog {
			max-width: 500px;
			width: 90vw;
		}
	}
</style>

<style lang="less" scoped>
	@media (min-width: 500px) and (max-width: 1190px) {
		.root {
			padding: 0px 30px;
		}
	}
	@media (max-width: 500px) {
		.language, .login-or-register, .user-info {
			display: none;
		}
  }
	@media (min-width: 1190px) {
		.root {
			padding: 0px;
		}
	}
</style>
