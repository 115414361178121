<template>
	<div class="fy" id="app">
		<!-- 请仅在ref存在时使用相应的provide函数 -->
		<div v-if="!noTool" class="appHeader fx-box">
			<toolbar ref="toolbar" @logoChange="logoChange" @userinfoChange="userinfoChange" :routeParam="routeParam"></toolbar>
		</div>
		<div class="appBody fy-list f-grow">
			<topbar ref="topbar" v-if="!noHeader" :hide="hideHeader" :routeType="routeType" :routeName="routeName" :routeParam="routeParam" />
			<router-view/>
		</div>
		<div v-if="!noFooter" class="appFooter fx-box">
			<bottom-pic></bottom-pic>
		</div>
	</div>
</template>

<script>
	import Toolbar from '@/components/Toolbar.vue';
	import Topbar from '@/components/Topbar.vue';
	import bottomPic from '@/components/bottomPic.vue';
	import { cookieGet, cookieSet } from '@/common/cookie';
	import { messages } from '@/i18n';
	import { setInstance } from '@/plugin/axios';
	import { appService } from '@/common/api';
	export default {
		name: 'app',
		components: {
			bottomPic,
			Toolbar,
			Topbar,
		},
		computed: {
			isClearWidth() {
				const u = navigator.userAgent;
				const routeName = this.routeName;
				const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
				const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

				return (isAndroid || isIOS) && (routeName === 'channel' || routeName === 'registrationSuccess' || routeName === 'login');
			},
		},
		data() {
			return {
				//可选的路由参数,每次进入新的路由,原来的参数都会被清空以保证即时性
				routeParam: {},
				//缓存参数,理论上类型应是固定的
				cacheParam: {
					//购物车数量
					cartNum: 0,
					//订单数量
					orderNum: 0,
				},
				//路由名
				routeName: this.$route.name,
				//根据路由名字映射出路由类型(即布局类型)
				routeType: this.$route.meta.type,
				//是否显示工具栏
				noTool: false,
				//是否显示页眉
				noHeader: false,
				//是否显示页脚
				noFooter: false,
				//是否仅保留实例
				hideHeader: false,
				// 注册登录
				showRegistry: !!this.$route.query.loginNow,
				showVerifyEmail: false,
			};
		},
		provide() {
			return {
				//设置路由参数,具体类型取决于路由的meta.type
				setRouteParam: function(p) {
					for (let k in p) {
						this.$set(this.routeParam, k, p[k]);
					}
				}.bind(this),
				// 设置缓存参数,缓存参数会在每次路由刷新时作为初始值加入routeParam对象
				// 为减少操作,设置缓存参数会自动同步到路由参数,无需另行调用代码
				setCacheParam: function(keyOrObj, value) {
					if (keyOrObj instanceof Object) {
						for (let k in value) {
							this.$set(this.cacheParam, k, value[k]);
							this.$set(this.routeParam, k, value[k]);
						}
					} else {
						this.cacheParam[keyOrObj] = value;
						this.routeParam[keyOrObj] = value;
					}
				}.bind(this),
				// 向子组件提供工具栏组件的实例,以支持功能调用
				// 同时,如果想获取信息更新,子组件应在setRouteParam设置相应的事件回调
				toolbar: function() {
					return this.$refs.toolbar;
				}.bind(this),
				topbar: function() {
					return this.$refs.topbar;
				}.bind(this),
			};
		},
		watch: {
			'$i18n.locale': 'i18nHandle',
		},
		created() {
			this.i18nHandle(this.$i18n.locale);
			this.$router.beforeEach(this.beforeEachGuard.bind(this));
			this.beforeEachGuard(this.$route, null, () => {});
			// setInstance(this);

			//如果不在登录页面且有登录状态,就初始化查询购物车和订单
			//否则,登录之后才会主动查一次数量,见login
			// if (cookieGet('token') && cookieGet('user') && this.$route.name !== 'login') {
			// 	//查一下即可,axios内有钩子函数负责设置结果,下同
			// 	appService.shoppingcartquery({
			// 		beginPage: 1,
			// 		rowSize: 0,
			// 	});
			// 	appService.orderqueryallList({
			// 		beginPage: 1,
			// 		rowSize: 0,
			// 	});
			// }
		},
		methods: {
			i18nHandle(val, oldVal) {
				cookieSet('language', val);
				document.querySelector('html').setAttribute('lang', val);
				document.body.className = val
				document.title = messages[val].picoCare;
				// 切换语言刷新页面
				if (oldVal) {
					window.location.reload();
				}
			},
			// logo改变事件,要刷新logo,源自组件toolbar
			logoChange() {
				if (typeof this.routeParam.logoChange === 'function') {
					this.routeParam.logoChange();
				}
				console.log('logoChange');
				// this.userLogo = cookieGet('userpic');
			},
			// 用户信息改变事件,源自组件toolbar
			userinfoChange(data) {
				if (typeof this.routeParam.userinfoChange === 'function') {
					this.routeParam.userinfoChange(data);
				}
				// this.$set(this, 'user', data);
				console.log('change', this.user);
			},
			beforeEachGuard(to, from, next) {
				this.routeParam = { ...this.cacheParam };
				console.log(this.cacheParam);
				this.routeName = to.name;
				this.routeType = to.meta.type;
				this.noTool = to.meta.noTool;
				this.noHeader = to.meta.noHeader;
				this.noFooter = to.meta.noFooter;
				this.hideHeader = to.meta.hideHeader;
				next();
			},
		},
	};
</script>

<style>
	/* 全局的,只用于html和body标签 */
	html,
	body {
		height: 100%;
		font-family: Avenir, Helvetica, Arial, sans-serif;
	}
	.en-US {
		font-family: Avenir, Helvetica, Arial, sans-serif;
	}
	.th-THA *:not(i){
		font-family: "SukhumvitSet", Avenir, Helvetica, Arial, sans-serif;
	}
	@font-face {
		font-family: 'SukhumvitSet';
		src: local('SukhumvitSet'),
		local('Sukhumvit-set'),
		url('/font/SukhumvitSet-Thin.ttf');
		font-weight: 100;
	}
	@font-face {
		font-family: 'SukhumvitSet';
		src: local('SukhumvitSet'),
		local('Sukhumvit-set'),
		url('/font/SukhumvitSet-Light.ttf');
		font-weight: 300;
	}
	@font-face {
		font-family: 'SukhumvitSet';
		src: local('SukhumvitSet'),
		local('Sukhumvit-set'),
		url('/font/SukhumvitSet-Normal.ttf');
		font-weight: 400;
	}
	@font-face {
		font-family: 'SukhumvitSet';
		src: local('SukhumvitSet'),
		local('Sukhumvit-set'),
		url('/font/SukhumvitSet-Medium.ttf');
		font-weight: 500;
	}
	@font-face {
		font-family: 'SukhumvitSet';
		src: local('SukhumvitSet'),
		local('Sukhumvit-set'),
		url('/font/SukhumvitSet-SemiBold.ttf');
		font-weight: 600;
	}
	@font-face {
		font-family: 'SukhumvitSet';
		src: local('SukhumvitSet'),
		local('Sukhumvit-set'),
		url('/font/SukhumvitSet-Bold.ttf');
		font-weight: 700;
	}
</style>

<style lang="less" scoped>
	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #2c3e50;
		min-height: 100%;
	}

	.toast-error {
		width: 200px !important;
	}
	.appHeader {
		background-color: #202124;
	}
	.appBody {
		// min-width: 1190px;
		box-sizing: border-box;
	}
	@media (min-width: 500px) and (max-width: 1190px) {
		.appBody {
			margin: 0px 30px;
		}
	}
	@media (max-width: 500px) {
		.appBody {
			margin: 0px;
		}
	}
	@media (min-width: 1190px) {
		.appBody {
			margin: 0px;
		}
	}
</style>
<style>
	.van-toast {
		z-index: 10000 !important;
		width: 150px !important;
		word-break: normal !important;
	}
</style>