let lastJump = null;

// 广告跳转传参用函数,注意这个函数不是什么时候调用都有有效值的
export function getLastJump() {
  return lastJump;
}

export default {
  data() {
    return {
      //枚举
      jumpTypeOption: {
        ALL_PRODUCT: 1,
        CATALOG_PRODUCT: 2,
        MERCHANT: 3,
        PRODUCT: 4,
      },
      jump: {
        jumpType: null,
        param1: '',
        param2: '',
        param3: ''
      }
    }
  },
  methods: {
    jumpHandle(jumpObj, isMI) {
      if (isMI) {
        // 如果页面是MI,禁止一切跳转,当作无事发生
        return;
      }
      console.log(jumpObj)
      this.jump = jumpObj.jump
      lastJump = null
      switch (this.jump.jumpType) {
        //商品详情
        case 'productItem':
          this.$router.push({ path: '/productDetails', query: { productItemId: this.jump.param1 } });
          break;
        //店铺详情
        case 'MERCHANT':
          this.$router.push({ path: '/storeDetails', query: { merchantid: this.jump.param1 } });
          break;
        // 商品目录
        case 'CATALOG_PRODUCT':
          lastJump = jumpObj;
          // 这里需要lastJump
          this.$router.push({ path: '/classifyGood', query: { catalogId: this.jump.param1, catalog: 'Y' } });
          break;
        //三级分类页面
        case 'THREE_CATEGORY':
          this.$router.push({ path: '/classifyGood', query: { catalogId: this.jump.param1 } });
          break;
        // h5页面
        case 'h5':
          window.open(this.jump.param1);
          break;
        // 任意url
        case 'url':
          window.location.href = this.jump.param1
          break;
      }
      /* h5
    productItem
    share
    url
    login
    wallet
    BUYFREE
    BUYFREEDETAIL
    ACTIVITY
    MERCHANT
    BRAND
    ALL_PRODUCT
    CATALOG_PRODUCT
    SEARCH_PRODUCT*/
    },
    jumpCallback(res) {
      console.log("jumpcallback" + res)
    }
  }
}
