import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from '@/plugin/axios'
import '@/permission'
// i18n
import i18n, { messages } from '@/i18n'

//引入vant-ui
import Vant from 'vant';
import 'vant/lib/index.css';
import { Dialog } from 'vant';
Vue.use(Vant);

//单独引入轻提示
import { Toast } from 'vant';
// Vue.use(Toast);
Vue.prototype.$Toast = Toast
//复制文本
import Clipboard from 'clipboard';
Vue.prototype.Clipboard = Clipboard
//引入nutui
// import NutUI from '@nutui/nutui';
// import '@nutui/nutui/dist/nutui.css';
// NutUI.install(Vue);

//element-ui(!尽量不使用)
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
//由于没安装scss,这里采用自定义主题文件
import '@/assets/style/theme/index.css';
/* Vue.use(ElementUI); */
// Element
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
})
// 引入模块后自动生效
import '@vant/touch-emulator';

//导一个自定义的样式声明
import '@/assets/style/custom.css';

Vue.config.productionTip = false
//打印
import Print from 'vue-print-nb'
Vue.use(Print);
// 挂载全局axios
Vue.prototype.$axios = axios

// 全局多语言设置
Vue.prototype.$languages = Object.keys(messages).map(langlage => ({
  label: messages[langlage]._lang,
  value: langlage
}))

// 全局注册vant Dialog
Vue.use(Dialog);
Vue.prototype.$Dialog = Dialog

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
