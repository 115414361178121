<template>
	<div class="login fx" style="justify-content: center">
		<div class="login-left">
			<!-- <img class="login-advert" :src="image" width="100%" height="auto"/> -->
			<advert class="login-advert" type="LOGIN" width="100%" height="auto"></advert>
		</div>
		<div class="login-right fy f-grow">
			<div class="font-bold fx-box" style="font-size: 26px">
				{{ $t('login.title') }}
			</div>
			<div class="fx-box" style="padding: 50px 0">
				<el-tabs v-model="loginType">
					<el-tab-pane :label="$t('login.email')" name="email"></el-tab-pane>
					<el-tab-pane :label="$t('login.phone')" name="phone"></el-tab-pane>
				</el-tabs>
			</div>
			<el-form :model="loginForm" :rules="formRules" ref="loginForm">
				<el-form-item v-if="loginType == 'email'" prop="email">
					<div class="font-l">{{ $t('login.email') }}</div>
					<el-input v-model="loginForm.email"></el-input>
				</el-form-item>
				<el-form-item v-if="loginType == 'phone'" prop="phone">
					<div class="font-l">{{ $t('login.phone') }}</div>
					<el-input v-model="loginForm.phone"></el-input>
				</el-form-item>
				<el-form-item v-if="loginType == 'phone'" prop="code" style="position: relative">
					<div class="font-l">{{ $t('login.code') }}</div>
					<div class="fx-list">
						<el-input v-model="loginForm.code" class="f-grow"></el-input>
						<el-button type="text" class="code-btn" @click.prevent="getCode()" :disabled="!show">
							<!-- <div style="position:absolute;top:39px;right:-80px" @click="getCode" :disabled="!show">{{$t('login.getCode')}}</div> -->
							<span v-show="show">{{ $t('login.getCode') }}</span>
							<span v-show="!show" class="count">{{ count }}</span>
						</el-button>
					</div>
				</el-form-item>

				<el-form-item v-if="loginType == 'email'" prop="password">
					<div class="font-l">{{ $t('login.password') }}</div>
					<el-input v-model="loginForm.password" type="password"></el-input>
				</el-form-item>
				<div v-if="loginType == 'phone'" class="font-m" style="padding-bottom: 10px; color: #999999">
					<span>{{ $t('login.registerTip3') }}</span>
				</div>
				<div class="font-m" style="padding-bottom: 140px; color: #999999">
					<span>{{ $t('login.registerTip1') }}</span>
					<span @click="$router.push('/register')" class="pointer" style="text-decoration: underline">{{ $t('login.registerTip2') }}</span>
				</div>
				<el-form-item>
					<el-checkbox v-model="loginForm.checked" class="fx" style="white-space: break-spaces">
						<span style="color: black">{{ $t('login.loginTip1') }}</span>
						<a href="" style="text-decoration: underline; color: black">{{ $t('login.loginTip2') }}</a>
						<span style="color: black">{{ $t('login.loginTip3') }}</span>
						<a href="" style="text-decoration: underline; color: black">{{ $t('login.loginTip4') }}</a>
					</el-checkbox>
				</el-form-item>
				<el-form-item>
					<el-button @click="loginSubmit" :disabled="!loginForm.checked" type="primary" class="loginButton">{{ $t('login.submit') }}</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import { cookieSet } from '@/common/cookie';
	import advert from '@/components/advert';
	export default {
		name: 'login',
		components: { advert },
		data() {
			return {
				loginType: 'email',
				loginForm: {
					email: '',
					phone: '',
					code: '',
					password: '',
					checked: false,
					country: '+66',
				},
				show: true,
				count: '',
				timer: '',
				TIME_COUNT: 60,
				image: '',
				formRules: {
					email: [
						{
							required: true,
							trigger: 'blur',
							message: this.$t('register.pleaseEnter'),
						},
					],
					phone: [
						{
							required: true,
							trigger: 'blur',
							message: this.$t('register.pleaseEnter'),
						},
					],
					code: [
						{
							required: true,
							trigger: 'blur',
							message: this.$t('register.pleaseEnter'),
						},
					],
					password: [
						{
							required: true,
							trigger: 'blur',
							message: this.$t('register.pleaseEnter'),
						},
					],
				},
			};
		},
		methods: {
			cookieSet,
			loginSubmit() {
				this.$refs['loginForm'].validate((valid) => {
					if (valid) {
						if (this.loginType == 'phone') {
							this.$axios({
								url: '/v1/pico/user/login',
								method: 'post',
								params: {
									country: '+66',
									mobile: this.loginForm.phone,
									verifycode: this.loginForm.code,
								},
							}).then((res) => {
								cookieSet('user', res.data.user);
								cookieSet('token', res.data.token);
								cookieSet('mobile', res.data.user.mobile);
								cookieSet('email', res.data.user.email);
								cookieSet('userName', res.data.user.userName);

								this.$router.push(sessionStorage.getItem('loginNext') || '/');
								sessionStorage.removeItem('loginNext');

								// 刷新
								this.$router.go(0);
							});
						} else if ((this.loginType = 'email')) {
							this.$axios({
								url: '/v1/pico/user/emailLogin',
								method: 'post',
								params: {
									country: '+66',
									password: this.loginForm.password,
									email: this.loginForm.email,
								},
							}).then((res) => {
								cookieSet('user', res.data.user);
								cookieSet('token', res.data.token);
								cookieSet('mobile', res.data.user.mobile);
								cookieSet('email', res.data.user.email);
								cookieSet('userName', res.data.user.userName);

								this.$router.push(sessionStorage.getItem('loginNext') || '/');
								sessionStorage.removeItem('loginNext');
								// 刷新
								this.$router.go(0);
							});
						}
					}
				});
			},
			getCode() {
				if (this.loginForm.phone) {
					console.log(!this.timer);
					if (!this.timer) {
						this.count = this.TIME_COUNT;
						this.show = false;
						this.$axios({
							url: '/v1/pico/customer/sendsms',
							method: 'post',
							params: {
								country: '+66',
								mobile: this.loginForm.phone,
							},
						}).then((res) => {
							console.log(res);
						});
						this.timer = setInterval(() => {
							if (this.count > 0 && this.count <= this.TIME_COUNT) {
								this.count--;
							} else {
								this.show = true;
								clearInterval(this.timer);
								this.timer = null;
							}
						}, 1000);
					}
				} else {
					this.$message(this.$t('register.pleaseEnter'));
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.code-btn {
		margin-left: 20px;
		color: #000000;
	}

	::v-deep .el-button--primary {
		border: none !important;
	}

	.login {
		width: 100%;
		max-width: 1190px;
		padding: 60px 0 180px 0;
	}
	.loginButton {
		border-radius: 1000px !important;
		color: white;
		width: 100%;
	}
	.count {
		position: absolute;
		top: 11px;
		right: 56px;
	}
	@media (min-width: 730px) and (max-width: 1190px) {
		.login-left {
			width: 46%;
			.login-advert {
				width: 100%;
			}
		}
		.login-right {
			width: 46%;
			padding: 0 4%;
		}
	}
	@media (min-width: 500px) and (max-width: 730px) {
		.login-left {
			display: none;
		}
		.login-right {
			max-width: 450px;
			padding: 0 30px;
		}
	}
	@media (max-width: 500px) {
		.login-left {
			display: none;
		}
		.login-right {
			max-width: 450px;
			padding: 0 30px;
		}
	}
	@media (min-width: 1190px) {
		.login-advert {
			width: 550px;
		}
		.login-right {
			padding: 0 45px 0 120px;
		}
	}
</style>

<style scoped>
	::v-deep .el-tabs__nav-wrap::after {
		position: static !important;
	}
	::v-deep .el-tabs__item {
		font-size: 22px !important;
	}
	::v-deep .el-tabs__item.is-active {
		color: black;
		font-weight: bold;
	}
	::v-deep .el-input__inner {
		border-radius: 5px !important;
		font-size: 18px !important;
	}

</style>
