import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/h5/login.vue'
import register from '../views/h5/register.vue'
import homePage from '../views/h5/homePage.vue'
import menu from '../views/h5/menu.vue'
import registerSuccess from '../views/h5/registerSuccess.vue'
import picoCheck from '../views/h5/picoCheck.vue'
import picoRegister from '../views/h5/picoRegister.vue'
import contact from '../views/h5/contact.vue'
Vue.use(VueRouter)

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

// 元数据type指代的header布局类型
// 类型1(默认), logo替换为pando care
// 类型2, logo替换为pico
// 其他,不显示附加信息
// 元数据noTool表示是否禁止顶部工具栏渲染
// 元数据noHeader表示是否禁止页眉渲染
// 元数据noFooter表示是否禁止页脚渲染
// 元数据hideHeader表示是否隐藏页眉
// no与hide的区别是no不会进行渲染且没有组件实例,hide不进行渲染但保留实例,仍能够调用组件方法
const routes = [
  {
    path: '/',
    name: 'homePage',
    component: homePage,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/register',
    name: 'register',
    component: register,
  },
  {
    path: '/menu',
    name: 'menu',
    component: menu,
  },
  {
    path: '/registerSuccess',
    name: 'registerSuccess',
    component: registerSuccess,
  },
  {
    path: '/picoRegister',
    name: 'picoRegister',
    component: picoRegister,
    meta: {
      noFooter: true,
      type: 2,
    }
  },
  {
    path: '/picoCheck',
    name: 'picoCheck',
    component: picoCheck,
    meta: {
      noFooter: true,
      type: 2,
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
  },
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes: routes
})

/* router.beforeEach((to, from, next) => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;

    // from.name为空的情况：首次进入，强制刷新
    if(from.name != null){
        sessionStorage.setItem('systemReload', 'routeJump')
    }
    // login页面弃用，重定向到首页
    else if(to.path === '/login') {
        next({ path: '/' })
    }
    else{
        next()
    }
}) */

export default router
