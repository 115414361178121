const t = {};
t.add = 'เพิ่ม';
t.picoCare = 'PICO Care'
t.input_confirm = 'ยืนยัน'
t.input_cancel = 'ยกเลิก'
t.SKU = 'รหัสสินค้า'
t.barcode = 'บาร์โค้ด'

// 底部
t.bottompic = {};
t.bottompic.aboutUs = 'เกี่ยวกับเรา';
t.bottompic.aboutPICO = 'เกี่ยวกับ PICO';
t.bottompic.aboutFanslink = "เกี่ยวกับแฟนสลิ้งค์";
t.bottompic.helpCenter = "ศูนย์ให้ความช่วยเหลือ";
t.bottompic.warrantyPolicy = "นโยบายการรับประกัน";
t.bottompic.privacyPolicy = 'นโยบายความเป็นส่วนตัว'
t.bottompic.followUs = "ติดตามเรา";

// 顶部1
t.toolbar = {}
t.toolbar.loginOrSignIn = 'เข้าสู่ระบบ / ลงทะเบียน'
t.toolbar.logout = 'ออกจากระบบ'
t.toolbar.changePasswd = 'เปลี่ยนรหัสผ่าน'
t.toolbar.accountnum = 'หมายเลขบัญชี'
t.toolbar.oldpassword = 'รหัสผ่านเก่า'
t.toolbar.newpassword = 'รหัสผ่านใหม่'
t.toolbar.confirmpassword = 'ยืนยันรหัสผ่าน'
t.toolbar.pleaseenter = 'กรุณาป้อน'

// 顶部2
t.topbar = {}
t.topbar.home = 'หน้าหลัก'
t.topbar.registerProductWarranty = 'ลงทะเบียนรับประกันสินค้า'
t.topbar.checkProductWarranty = 'ตรวจสอบประกันสินค้า'
t.topbar.contact = 'ติดต่อเรา'

// 联系我们
t.contact = {}
t.contact.follow = 'ติดตามเรา'

// 产品
t.product = {}
t.product.list = 'สินค้าของฉัน'
t.product.add = 'เพิ่มสินค้า'
t.product.detail = 'รายละเอียดสินค้า'
t.product.search = 'ค้นหาสินค้า หรือหมายเลข SKU'
t.product.eng = 'คู่มือการใช้งานภาษาอังกฤษ'
t.product.thai = 'คู่มือการใช้งานภาษาไทย'
t.product.video = 'วิดีโอสอนการใช้งาน'
t.product.tip1 = 'ไม่พบสินค้าที่คุณค้นหา กรุณาค้นหาสินค้าใหม่'
t.product.tip2 = 'ไม่มีรายการสินค้า'
t.product.registration ='ลงทะเบียนรับประกันสินค้า'
t.product.sku = 'หมายเลข SKU'
t.product.name = 'ชื่อสินค้า'
t.product.serialNumber = 'หมายเลขซีเรียล'
t.product.address = 'คุณซื้อสินค้าที่ไหน'
t.product.purchaseDate = 'วันที่ซื้อ'
t.product.expirtyDate = 'วันหมดอายุการรับประกัน'
t.product.registrationDate = 'วันที่ลงทะเบียน'
t.product.upload = 'อัพโหลดใบเสร็จ'
t.product.uploadTip = 'ลากไฟล์มาที่นี่'
t.product.provide_SHOPEE = 'โปรดเลือกชื่อร้านค้า'
t.product.provide_LAZADA = 'โปรดเลือกชื่อร้านค้า'
t.product.provide_FACEBOOK = 'โปรดระบุชื่อเพจที่ทำการสั่งซื้อ'
t.product.provide_TIKTOK = 'โปรดเลือกชื่อร้านค้า'
t.product.provide_SHOP = 'โปรดเลือกชื่อร้านค้า'
t.product.provide_OTHER = 'โปรดระบุรายละเอียดเพิ่มเติม'
t.product.search = 'ค้นหา'

// PICO
t.pico = {};
t.pico.goto = 'ตรวจสอบการรับประกันสินค้าของฉัน';
t.pico.gotoRegister = 'ลงทะเบียนการรับประกันสินค้า';
t.pico.registration = 'การลงทะเบียนการรับประกัน Pico';
t.pico.firstname = 'ชื่อ';
t.pico.firstnameTip = 'กรุณาใส่ชื่อของคุณ';
t.pico.lastname = 'นามสกุล';
t.pico.lastnameTip = 'กรุณาใส่นามสกุลของคุณ';
t.pico.email = 'ที่อยู่อีเมล';
t.pico.emailTip = 'กรุณากรอกอีเมลของคุณ';
t.pico.reEmail = 'ป้อนที่อยู่อีเมลอีกครั้ง';
t.pico.contactnum = 'เบอร์ติดต่อ';
t.pico.contactnumTip = 'กรุณาใส่หมายเลขโทรศัพท์ติดต่อของคุณ';
t.pico.name = 'ชื่อสินค้า';
t.pico.nameTip = 'กรุณาเลือกสถานที่ซื้อของคุณ';
t.pico.serialNumber = 'หมายเลขของอุปกรณ์';
t.pico.serialNumberTip = 'กรอกตัวอักษรให้ครบ 17 ตัว';
t.pico.purchaseDate = 'วันที่ซื้อ';
t.pico.purchaseDateTip1 = 'หากป้อนวันที่ซื้อไม่ถูกต้อง';
t.pico.purchaseDateTip2 = 'การเคลมสินค้าในอนาคตจะได้รับผลกระทบ';
t.pico.purchaseFrom = 'สั่งซื้อจาก';
t.pico.purchaseFromTip = 'กรุณาเลือกสถานที่ซื้อของคุณ';
t.pico.online = 'ลาซาด้า/ช้อปปี้/เซนทรัลออนไลน์';
t.pico.store = 'ร้านค้าปลีก Pico';
t.pico.com7orBanana = 'COM7/BANANA';
t.pico.tgPhone = 'TG PHONE';
t.pico.jaymart = 'JAYMART';
t.pico.life = 'Cooperwired/.Life';
t.pico.lakecom = 'LAKECOM';
t.pico.nava = 'NAVA ASIA';
t.pico.orderNum = 'เลขที่ใบเสร็จ / หมายเลขคำสั่งซื้อออนไลน์';
t.pico.orderNumTip = 'กรุณาใส่หมายเลขการสั่งซื้อออนไลน์ของคุณ';
t.pico.uploadProof = 'อัปโหลดหลักฐานการซื้อ:';
t.pico.uploadProofTip1 = 'กรุณาแนบไฟล์ jpg, png หรือ PDF (สูงสุดขนาด 1MB)';
t.pico.uploadProofTip2 = 'ต้องมีเลขที่ใบเสร็จและวันที่ที่ชัดเจนสำหรับการเคลม';
t.pico.uploadTip = 'ลากและวางไฟล์ที่นี่ ...';
t.pico.selectFile = 'เลือกไฟล์...';
t.pico.browse = 'เรียกดู...';

t.productList = {};
t.productList.title = 'รายการสินค้าของฉัน';
t.productList.detail = 'รายละเอียดสินค้า';
t.productList.purchaseDate = 'วันที่ซื้อ';
t.productList.warrantyData = 'วันหมดอายุการรับประกัน';
t.productList.registerData = 'วันที่ลงทะเบียน';

// 登录
t.login = {}
t.login.title = 'เข้าสู่ระบบ'
t.login.email = 'อีเมล์'
t.login.phone = 'โทรศัพท์'
t.login.code = 'รหัสยืนยัน'
t.login.getCode = 'ขอรหัสยืนยัน'
t.login.password = 'รหัสผ่าน'
t.login.registerTip1 = 'ยังไม่ได้เป็นสมาชิก? '
t.login.registerTip2 = 'ลงทะเบียน'
t.login.registerTip3 = `ยังไม่ได้รับ? ส่งใหม่อีกครั้ง`
t.login.loginTip1 = 'ฉันได้อ่าน และยอมรับ '
t.login.loginTip2 = 'ข้อกำหนดและเงื่อนไข'
t.login.loginTip3 = ' และ '
t.login.loginTip4 = 'นโยบายความเป็นส่วนตัว'
t.login.submit = 'ส่ง'

// 注册
t.register = {}
t.register.accountInfo = 'ข้อมูลเกี่ยวกับบัญชี'
t.register.username = 'ชื่อผู้ใช้งาน'
t.register.password = 'รหัสผ่าน'
t.register.confirmPassword = 'ยืนยันรหัสผ่าน'
t.register.personalInfo = 'ข้อมูลส่วนตัว'
t.register.firstname = 'ชื่อ'
t.register.lastname = 'นามสกุล'
t.register.nickname = 'ชื่อเล่น'
t.register.gender = 'เพศ'
t.register.gender_Female = 'ผู้หญิง'
t.register.gender_Male = 'ผู้ชาย'
t.register.gender_Secret = 'ไม่ต้องการให้ข้อมูล'
t.register.birth = 'วันเกิด'
t.register.mobile = 'เบอร์มือถือ'
t.register.email = 'อีเมล์'
t.register.address = 'ที่อยู่'
t.register.province = 'จังหวัด'
t.register.district = 'เขต'
t.register.postalCode = 'รหัสไปรษณีย์'
t.register.detailAddress = 'รายละเอียดที่อยู่'
t.register.submit = 'ส่ง'
t.register.pleaseEnter = 'กรุณาป้อน'
t.register.minNine = 'เบอร์ติดต่อต้องมีตัวเลขไม่ต่ำกว่า 9 ตัว';
t.register.validateEmail = 'ที่อยู่อีเมลไม่ตรงกัน';
t.register.formatEmail = 'กรุณาป้อนรูปแบบอีเมลที่ถูกต้อง';
t.register.emailFormatError = 'รูปแบบอีเมล์ไม่ถูกต้อง'
t.register.passwordDifferent = 'ยืนยันรหัสผ่านไม่ถูกต้อง'
t.register.registerTip = 'ฉันยอมรับ และตกลงตามข้อกำหนด และเงื่อนไขในการให้บริการของบริษัท แฟนสลิ้งค์ คอมมูนิเคชั่น จำกัด และฉันได้อ่าน และรับทราบถึงนโยบายความเป็นส่วนตัวของบริษัท แฟนสลิ้งค์ คอมมูนิเคชั่น จำกัด แล้ว'
t.register.checkbox = 'กรุณาทำเครื่องหมายในช่อง';

t.registerSuccess = {}
t.registerSuccess.title = 'บันทึกสำเร็จ'
t.registerSuccess.tip = 'ขอบคุณสำหรับความสนใจค่ะ ทางเราจะติดต่อกลับในภายหลัง ทางอีเมลล์ที่คุณระบุไว้ข้างต้น.'
t.registerSuccess.home = 'กลับสู่หน้าหลัก'

export default t;