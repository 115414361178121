<template>
	<div class="register fy-box">
		<div class="register-advert">
			<!-- <img :src="image?image:'/images/banner5.png'" width="100%"/> -->
			<advert type="PRODUCT" width="100%" height="auto"></advert>
		</div>
		<div class="goto" @click="$router.push('/picoCheck')">
			<span class="goto-right">→</span>
			<span class="goto-text">{{ $t('pico.goto') }}</span>
		</div>
		<div class="fy f-grow register-form">
			<el-form :model="registerForm" :rules="formRules" ref="registerForm" label-width="300px">
				<div class="font-xxl font-bold form-title">{{ $t('pico.registration') }}</div>
				<!-- <el-form-item :label="'1. ' + $t('pico.firstname')" prop="firstName">
					<el-input v-model="registerForm.firstName"></el-input>
					<div class="label-desc">{{ $t('pico.firstnameTip') }}</div>
				</el-form-item>
				<el-form-item :label="'2. ' + $t('pico.lastname')" prop="lastName">
					<el-input v-model="registerForm.lastName"></el-input>
					<div class="label-desc">{{ $t('pico.lastnameTip') }}</div>
				</el-form-item>
				<el-form-item :label="'3. ' + $t('pico.email')" prop="emailAddress">
					<el-input v-model="registerForm.emailAddress"></el-input>
					<div class="label-desc">{{ $t('pico.emailTip') }}</div>
				</el-form-item>
				<el-form-item :label="'4. ' + $t('pico.reEmail')" prop="reEmailAddress">
					<el-input v-model="registerForm.reEmailAddress"></el-input>
					<div class="label-desc">{{ $t('pico.emailTip') }}</div>
				</el-form-item>
				<el-form-item :label="'5. ' + $t('pico.contactnum')" prop="contactNumber">
					<el-input v-model="registerForm.contactNumber"></el-input>
					<div class="label-desc">{{ $t('pico.contactnumTip') }}</div>
				</el-form-item> -->
				<el-form-item :label="'1. ' + $t('pico.name')" prop="selectedPico">
					<el-select :popper-append-to-body="false" style="width: 100%" v-model="registerForm.selectedPico" value-key="barCode">
						<el-option v-for="item in picoOptions" :key="item.barCode" :label="item.productName" :value="item"></el-option>
					</el-select>
					<div class="label-desc">{{ $t('pico.nameTip') }}</div>
				</el-form-item>
				<el-form-item :label="'2. ' + $t('pico.serialNumber')" prop="serialNumber">
					<el-input v-model="registerForm.serialNumber"></el-input>
					<div class="label-desc">{{ $t('pico.serialNumberTip') }}</div>
				</el-form-item>
				<el-form-item :label="'3. ' + $t('pico.purchaseDate')" prop="purchaseDate">
					<el-date-picker
						style="width: 100%"
						v-model="registerForm.purchaseDate"
						type="date"
						:picker-options="pickerOptions"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd">
					</el-date-picker>
					<div class="label-desc">
						<span>{{ $t('pico.purchaseDateTip1') }}</span
						><br />
						<span>{{ $t('pico.purchaseDateTip2') }}</span>
					</div>
				</el-form-item>
				<el-form-item :label="'4. ' + $t('pico.purchaseFrom')" prop="purchasedFrom">
					<!-- <el-select :popper-append-to-body="false" style="width: 100%" v-model="registerForm.address">
						<el-option key="shopee" :label="$t('product.shopee')" value="SHOPEE"></el-option>
						<el-option key="lazada" :label="$t('product.lazada')" value="LAZADA"></el-option>
						<el-option key="appShop" :label="$t('product.appShop')" value="RABBIT_SELECTION_APP$SHOP"></el-option>
						<el-option key="facebook" :label="$t('product.facebook')" value="FACEBOOK"></el-option>
						<el-option key="tiktok" :label="$t('product.tiktok')" value="TIKTOK"></el-option>
						<el-option key="shop" :label="$t('product.shop')" value="SHOP"></el-option>
						<el-option key="gift" :label="$t('product.gift')" value="RECEIVED_AS_A_GIFT"></el-option>
					</el-select> -->
					<el-select :popper-append-to-body="false" style="width: 100%" v-model="registerForm.purchasedFrom">
						<el-option key="online" :label="$t('pico.online')" value="CENTRAL_ONLINE"></el-option>
						<el-option key="store" :label="$t('pico.store')" value="PICO"></el-option>
						<el-option key="banana" :label="$t('pico.com7orBanana')" value="COM7"></el-option>
						<el-option key="tg" :label="$t('pico.tgPhone')" value="TG_PHONE"></el-option>
						<el-option key="jaymart" :label="$t('pico.jaymart')" value="JAYMART"></el-option>
						<el-option key="life" :label="$t('pico.life')" value="COOPERWIRED"></el-option>
						<el-option key="lakecom" :label="$t('pico.lakecom')" value="LAKECOM"></el-option>
						<el-option key="nava" :label="$t('pico.nava')" value="NAVA_ASIA"></el-option>
						<el-option key="other" :label="$t('product.other')" value="OTHER"></el-option>
					</el-select>
					<div class="label-desc">{{ $t('pico.purchaseFromTip') }}</div>
				</el-form-item>

				<el-form-item prop="shopname" v-if="registerForm.purchasedFrom === 'OTHER'" :label="$t('product.provide_OTHER')">
					<el-input v-model="registerForm.shopname"></el-input>
				</el-form-item>
				<!-- <el-form-item :label="'10. ' + $t('pico.orderNum')" prop="orderNumber">
					<el-input v-model="registerForm.orderNumber"></el-input>
					<div class="label-desc">{{ $t('pico.orderNumTip') }}</div>
				</el-form-item> -->
				<el-form-item :label="'5. ' + $t('pico.uploadProof')" prop="pictureId" v-if="registerForm.purchasedFrom !== 'RECEIVED_AS_A_GIFT'">
					<el-upload
						:action="action"
						:headers="headers"
						:file-list="fileList"
						:limit="1"
						name="file1"
						:on-exceed="handleExceed"
						:on-success="uploadSuccess"
						:before-upload="beforeUpload"
						:on-progress="uploading"
						multiple
						drag>
						<div class="el-upload el-upload--picture-card upload-box">
							<!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
							{{ $t('pico.uploadTip') }}
						</div>
						<template #file="{ file: fileOrObj }">
							<li tabindex="0" class="el-upload-list__item is-success">
								<a class="el-upload-list__item-name"><i class="el-icon-document"></i>{{ fileOrObj.name }} </a>
								<label class="el-upload-list__item-status-label">
									<i class="el-icon-upload-success el-icon-circle-check"></i>
								</label>
								<i class="el-icon-view mr4" @click="handlePictureCardPreview(fileOrObj)"></i>
								<i class="el-icon-close" @click="handleDelete(fileOrObj)"></i>
							</li>
						</template>
					</el-upload>
					<div class="label-desc">
						<span>{{ $t('pico.uploadProofTip1') }}</span
						><br />
						<span>{{ $t('pico.uploadProofTip2') }}</span>
					</div>
				</el-form-item>
				<div class="fx mt8">
					<el-checkbox class="mx6" v-model="checkedOne"></el-checkbox>
					<p class="my0 mx8e lh5">
						<span>ฉันยอมรับเงื่อนไขการรับประกัน</span>
						<br />
						<br />
						<span>I accept and agree to the <u>Warranty Terms & Conditions.</u></span>
						<br />
						<br />
					</p>
					<!-- <pre class="m0">
                    ข้าพเจ้าตกลงและยอมรับเงื่อนไขและข้อกำหนดของบริการ （Terms and Conditions) ของบริษัท แฟนสลิ้งค์ คอมมูนิเคชั่น จํากัดและข้าพเจ้าได้อ่านและรับทราบถึงนโยบายความเป็นส่วนตัว (Privacy Policy) ของบริษัท แฟนสลิ้งค์ คอมมูนิเคชั่น จํากัด 
                    I accept and agree to the Terms and Conditions of Services of Fanslink Communication Co., Ltd., and I have read and acknowledge the Privacy Policy of Fanslink Communication Co., Ltd.
                </pre> -->
				</div>
				<div class="fx mt8">
					<el-checkbox class="mx6" v-model="checkedTwo"></el-checkbox>
					<p class="my0 mx8e lh5">
						<span
							>ข้าพเจ้าตกลงและยอมรับเงื่อนไขและข้อกำหนดของบริการ （Terms and Conditions) ของบริษัท แฟนสลิ้งค์ คอมมูนิเคชั่น
							จํากัดและข้าพเจ้าได้อ่านและรับทราบถึงนโยบายความเป็นส่วนตัว (Privacy Policy) ของบริษัท แฟนสลิ้งค์ คอมมูนิเคชั่น จํากัด
						</span>
						<br />
						<br />
						<span
							>I accept and agree to the Terms and Conditions of Services of Fanslink Communication Co., Ltd., and I have read and acknowledge the
							Privacy Policy of Fanslink Communication Co., Ltd.</span
						>
					</p>
				</div>
				<div class="fx-box mt10">
					<el-button type="primary" @click="submitForm">{{ $t('register.submit') }}</el-button>
				</div>
			</el-form>

			<el-dialog :visible.sync="dialogVisible" v-if="dialogVisible" width="800px">
				<embed :src="dialogImageUrl" class="w10" style="height: 700px" />
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { cookieGet } from '@/common/cookie';
	import advert from '@/components/advert';
	export default {
		name: 'register',
		components: { advert },
		data() {
			return {
				registerForm: {
					barCode: '',
					skuCode: '',
					contactNumber: '',
					emailAddress: '',
					firstName: '',
					lastName: '',
					id: '',
					orderNumber: '',
					pictureId: '',
					productName: '',
					purchaseDate: '',
					purchasedFrom: '',
					reEmailAddress: '',
					remark: '',
					serialNumber: '',
					selectedPico: {},
					shopname: '',
				},
				fileList: [],
				image: '',
				uploadFileUrl: '',
				dialogVisible: false,
				dialogImageUrl: '',
				formRules: {
					selectedPico: [
						{
							required: true,
							trigger: 'change',
							validator: (rule, value, callback) => {
								if (Object.keys(value).length > 0) {
									callback();
								} else {
									callback(new Error());
								}
							},
							message: this.$t('register.pleaseEnter'),
						},
					],
					contactNumber: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
						{
							type: 'string',
							min: 9,
							message: this.$t('register.minNine'),
							trigger: 'blur',
						},
					],
					emailAddress: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
						{
							type: 'email',
							message: this.$t('register.formatEmail'),
							trigger: ['blur', 'change'],
						},
					],
					firstName: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					lastName: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					orderNumber: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					pictureId: [
						{
							required: true,
							// message: this.$t('register.pleaseEnter'),
							validator: (rule, value, callback) => {
								if (!value) {
									callback(new Error(this.$t('register.pleaseEnter')));
								} else {
									callback();
								}
							},
							trigger: 'blur',
						},
					],
					productName: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					purchaseDate: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					purchasedFrom: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'change',
						},
					],
					reEmailAddress: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
						{
							validator: (rule, value, callback) => {
								if (value !== this.registerForm.emailAddress) {
									callback(new Error(this.$t('register.validateEmail')));
								} else {
									callback();
								}
							},
							trigger: 'blur',
							message: this.$t('register.validateEmail'),
						},
					],
					serialNumber: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					date: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					shopname: [
						{
							required: true,
							message: this.$t('register.pleaseEnter'),
							trigger: 'blur',
						},
					],
					// pictureId: [
					// 	{
					// 		required: true,
					// 		message: this.$t('register.pleaseEnter'),
					// 		trigger: 'change',
					// 	},
					// ],
				},
				pickerOptions: {
					disabledDate(time) {
						// console.log('1420',time);
						//选择今天以及今天之前的日期
						// time.getTime选中的
						// date.now当前的
						// 选中的小于当前日期，则返回true，就是可选
						const sixMonth = new Date().setMonth(new Date().getMonth() - 3);
						return time.getTime() > Date.now() || time.getTime() < sixMonth;
					},
				},
				picoOptions: [
					{
						productName: 'PICO 4 VR Headset (Phoenix HMD) EU (8G+128G)',
						barCode: '6970214572096',
						skuCode: 'PCVR8128WHT',
					},
					{
						productName: 'PICO 4 VR Headset (Phoenix HMD) EU (8G+256G)',
						barCode: '6970214571563',
						skuCode: 'PCVR8526WHT',
					},
					{
						productName: 'Pico 4 Ultra/AP (12G+256G)',
						barCode: '6970214573901',
						skuCode: 'P9001SW40677H',
					},
				],
				checkedOne: false,
				checkedTwo: false,
			};
		},
		computed: {
			action() {
				return process.env.VUE_APP_API + 'v1/upload/fileforall';
			},
			headers() {
				return {
					Token: cookieGet('token'),
				};
			},
		},
		methods: {
			handleUploaded(response, file, fileList) {},
			handleRemove(file, fileList) {},
			/* 超出文件数量限制 提示 */
			handleExceed(files, fileList) {
				// this.$message(this.$t('upload.exceed'));
			},

			/* 文件类型限制 jpg或png */
			beforeUpload(file) {
				if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
					return true;
				} else {
					//获取最后一个.的位置
					var index = file.name.lastIndexOf('.');
					//获取后缀
					var ext = file.name.substr(index + 1);
					var canUpload = ['pdf', 'jpg', 'png'];
					if (canUpload.indexOf(ext.toLowerCase()) != -1) {
						return true;
					} else {
						this.$message.warning(`Incorrect file type`);
						return false;
					}
				}
			},
			uploadSuccess(response, file) {
				if (response.code != 0) {
					this.$message.error(response.message);
				}
				file.id = response.list[0].id;
				this.registerForm.pictureId = file.id;
				this.fileList.push({
					id: file.id,
					url: response.list[0].image,
					image: response.list[0].image,
					name: file.name,
				});
				this.$refs.registerForm.validateField('pictureId');
			},
			uploading(event, file, fileList) {
				console.log(event, file, fileList);
			},
			//删除文件
			handleDelete(file) {
				this.fileList = this.fileList.filter((item) => {
					return item.id != file.id;
				});
			},
			//预览图片
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			submitForm() {
				this.registerForm.barCode = this.registerForm.selectedPico.barCode;
				this.registerForm.skuCode = this.registerForm.selectedPico.skuCode;
				this.registerForm.productName = this.registerForm.selectedPico.productName;
				this.$refs['registerForm'].validate((valid) => {
					if (this.fileList.length != 0) {
						var file = this.fileList.map((item) => {
							return {
								docFileId: item.id,
								id: '',
							};
						});
					}

					if (valid) {
						if (!this.checkedOne || !this.checkedTwo) {
							return this.$message(this.$t('register.checkbox'));
						}
						if (this.registerForm.emailAddress !== this.registerForm.reEmailAddress) {
							return;
						}
						this.$axios({
							url: '/v1/pico/warranty/product/record/save',
							method: 'post',
							headers: { token: this.headers.Token, 'Content-Type': 'application/json' },
							data: { ...this.registerForm, noQs: true },
						}).then((res) => {
							this.$message({
								type: 'success',
								message: this.$t('product.success'),
							});
							this.resetForm();
							window.scrollTo({
								left: 0,
								top: 0,
								behavior: 'smooth',
							});
						});
					} else {
						// 校验不通过，滚动到第一个校验失败的表单项
						this.$nextTick(() => {
							const firstError = this.$el.querySelector('.is-error .el-form-item__label');
							if (firstError) {
								const form = this.$refs.registerForm.$el;
								firstError.scrollIntoView({
									behavior: 'smooth',
									block: 'center',
								});
							}
						});
					}
				});
			},
			resetForm() {
				this.$refs.registerForm.resetFields();
				this.registerForm.productName = '';
				this.registerForm.skuCode = '';
				this.registerForm.barCode = '';
				this.registerForm.selectedPico = {};
				this.fileList = [];
				this.checkedOne = false;
				this.checkedTwo = false;
			},
		},
	};
</script>

<style scoped>
	.register {
		width: 100%;
		max-width: 1190px;
		padding: 60px 0 50px 0;
	}
	.register-form {
		padding: 30px;
	}
	.goto {
		align-self: flex-start;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.goto-right {
		font-weight: 600;
		color: #3f00f2;
		font-size: large;
		margin-right: 2px;
	}
	.goto-text {
		font-size: 18px;
		color: #807e7e;
		text-decoration: underline;
	}
	@media (max-width: 500px) {
		.register-advert {
			display: none;
		}
		::v-deep .el-form-item {
			display: flex !important;
			flex-direction: column !important;
		}
		::v-deep .el-form-item__content {
			margin-left: 0 !important;
		}
		::v-deep .el-form-item__label {
			text-align: left !important;
		}
		.position {
			display: none;
		}
		.loadposition {
			display: none;
		}
		.label-desc {
			display: none;
		}
		.goto {
			margin-left: 20px;
		}
	}
	@media (min-width: 501px) and (max-width: 649px) {
		::v-deep .el-form-item {
			display: flex !important;
			flex-direction: column !important;
		}
		::v-deep .el-form-item__content {
			margin-left: 0 !important;
		}
		::v-deep .el-form-item__label {
			text-align: left !important;
		}
		.position {
			position: relative;
			top: -36px;
			left: 309px;
		}
		.loadposition {
			position: relative;
			top: -145px;
			left: 309px;
		}
		.label-desc {
			display: none;
		}
	}
	@media (min-width: 650px) and (max-width: 1189px) {
		.register-form {
			width: 590px;
			padding: 55px 0 0 0;
		}
		.form-title {
			padding: 0 15px 30px;
		}
		.position {
			position: relative;
			top: -36px;
			left: 309px;
		}
		.loadposition {
			position: relative;
			top: -145px;
			left: 309px;
		}

		.label-desc {
			position: absolute;
			top: 29px;
			width: 324px;
			right: 340px;
			color: #8d8b8b;
			text-align: end;
			line-height: 19px;
		}
	}
	@media (min-width: 1190px) {
		.register-form {
			padding: 55px 350px 0 250px;
		}
		.form-title {
			padding: 0 15px 30px;
		}
		.fx-box {
			justify-content: flex-end;
		}
		.position {
			position: relative;
			top: -36px;
			left: 275px;
		}
		.loadposition {
			position: relative;
			top: -145px;
			left: 275px;
		}
		.label-desc {
			position: absolute;
			top: 29px;
			width: 324px;
			right: 340px;
			color: #8d8b8b;
			text-align: end;
			line-height: 19px;
		}
	}
</style>

<style scoped>
	::v-deep .el-form-item__label {
		font-weight: bold !important;
		color: #5e5a5a;
		padding-right: 20px;
	}
	::v-deep .el-form-item__content {
		position: relative;
		width: 320px;
	}
	::v-deep .el-input__inner {
		border-radius: 5px !important;
	}
	::v-deep .el-button {
		border-radius: 1000px !important;
		font-size: 20px !important;
		width: 80%;
		max-width: 400px;
		height: 50px;
	}
	::v-deep .el-upload-dragger {
		width: 290px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: #e4e7ed solid 1px;
	}
	.upload-box {
		padding: 0 20px;
		background-color: #ffffff;
		width: 260px;
		height: 160px;
		line-height: 160px;
		color: #9f9f9f;
		font-size: 18px;
	}
</style>
