var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-box"},[_c('div',{staticClass:"product-advert"},[_c('advert',{attrs:{"type":"CONTACT","width":"100%","height":"auto"}})],1),_c('div',{staticClass:"contact-box"},[_c('h4',{staticClass:"f-start mt5 mb0"},[_vm._v(_vm._s(_vm.$t('topbar.contact')))]),_c('div',{staticClass:"fx-list"},[_c('div',{staticClass:"fy f-grow"},[_vm._m(0),_c('span',{staticClass:"mt4 mb8"},[_vm._v("PICO-XR-Thailand LINE Official Account")]),_c('a',{attrs:{"href":"https://manager.line.biz/account/@396gahlf","target":"_blank"}},[_c('el-button',{staticClass:"followBtn",attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.$t('contact.follow')))])],1)]),_vm._m(1)]),_c('div',{staticClass:"fx-list"},[_c('div',{staticClass:"fy f-grow"},[_vm._m(2),_c('span',{staticClass:"mt4 mb8"},[_vm._v("PICO XR TH")]),_c('a',{attrs:{"href":"https://www.tiktok.com/@picoxr_th","target":"_blank"}},[_c('el-button',{staticClass:"followBtn",attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.$t('contact.follow')))])],1)]),_vm._m(3)]),_c('div',{staticClass:"fx-list"},[_c('div',{staticClass:"fy f-grow"},[_vm._m(4),_c('span',{staticClass:"mt4 mb8"},[_vm._v("PICO XR Official Thailand Community")]),_c('a',{attrs:{"href":"https://www.facebook.com/groups/660041976135970","target":"_blank"}},[_c('el-button',{staticClass:"followBtn",attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.$t('contact.follow')))])],1)]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fx-list"},[_c('img',{staticClass:"icon",attrs:{"src":"/images/line-icon.png"}}),_c('span',{staticClass:"font-xl font-bold"},[_vm._v("LINE OA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qr"},[_c('img',{staticClass:"p2",attrs:{"src":"/images/line-qr.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fx-list"},[_c('img',{staticClass:"icon",attrs:{"src":"/images/tiktok-icon.png"}}),_c('span',{staticClass:"font-xl font-bold"},[_vm._v("TikTok")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qr"},[_c('img',{staticClass:"p2",attrs:{"src":"/images/tiktok-qr.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fx-list"},[_c('img',{staticClass:"icon",attrs:{"src":"/images/facebook-icon.png"}}),_c('span',{staticClass:"font-xl font-bold"},[_vm._v("Facebook")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qr"},[_c('img',{staticClass:"p2",attrs:{"src":"/images/facebook-qr.png"}})])
}]

export { render, staticRenderFns }