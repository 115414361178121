<template>
  <div class="register-success fy-box">
    <img class="w3" src="/images/register_success_advert.png" alt="">
    <div class="font-bold font-xxl my4">{{ $t('registerSuccess.title') }}</div>
    <!-- <div class="m4" style="text-align: center;">{{ $t('registerSuccess.tip') }}</div> -->
    <el-button class="mt4" type="primary" @click="$router.push('/')">{{ $t('registerSuccess.home') }}</el-button>
  </div>
</template>

<script>
  export default {
    name: 'register',
  }
</script>

<style lang="less" scoped>
  .register-success {
    padding-bottom: 100px;
    width: 100%;
  }
	@media (min-width: 1190px) {
    .register-success {
      width: 1190px;
    }
  }
</style>

<style scoped>
  ::v-deep .el-button {
    border-radius: 5px !important;
  }
</style>