<template>
	<div class="root fx-between-box anchor">
		<div class="view">
			<div class="fx-between-box">
				<div v-if="routeType === undefined || routeType === 1" class="pointer mr7 logo-container" @click="$router.push('/')">
					<img src="/images/PICO_Logo.png"/>
				</div>
				<div v-else-if="routeType === 2" class="pointer mr7 logo-container">
					<img src="/images/PICO_Logo.png"/>
				</div>
				<div class="f-grow"></div>
				<div class="fx menu-all">
					<div class="mx4 pointer" @click="$router.push('/')">{{ $t('topbar.home') }}</div>
					<div class="mx4 pointer" @click="goToProductPage('picoRegister')">{{ $t('topbar.registerProductWarranty') }}</div>
					<div class="mx4 pointer" @click="goToProductPage('picoCheck')">{{ $t('topbar.checkProductWarranty') }}</div>
					<div class="mx4 pointer" @click="goToProductPage('contact')">{{ $t('topbar.contact') }}</div>
				</div>
				<div v-if="$route.path != '/menu'" class="menu-icon" style="padding-right: 30px;">
					<img @click="$router.push('/menu')" src="/images/menu.svg" alt="">
				</div>
				<div v-if="$route.path == '/menu'" class="close-icon" style="padding-right: 30px;">
					<img @click="$router.go(-1)" src="/images/close.svg" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { cookieGet } from '@/common/cookie';

	export default {
		props: {
			//路由参数,某些特定的路由会用到,结构由路由目标组件提供
			routeParam: {
				type: Object,
				default: () => {
					return {};
				},
			},
			routeType: {
				default: undefined,
			},
			routeName: {
				type: String,
				require: true,
			},
			/**
			 * 注意,在元数据启用hide模式,意味着主页面要自己渲染页眉
			 * method内的方法仍然是有效的,前提是要正确接入相关的参数
			 * 在自定义渲染时,可以选择借用这里的函数,也可选择自行定义一个新的
			 */
			hide: {
				default: undefined,
			}
		},
		data() {
			return {
			};
		},
		watch: {
		},
		computed: {},
		mounted() {
		},
		methods: {
			init() {
			},
			cookieGet,
			goToProductPage(url){
				// this.$router.push('/' + url);
				if(cookieGet('token')){
					this.$router.push('/' + url);
				}else{
					this.$router.push('/login');
				}
			},
		},
		created() {
			this.init();
		},
		beforeDestroy() {
			console.log('before destory');
		},
	};
</script>

<style lang="less" scoped>
	.root {
		width: 100%;
		.view {
			width: 100%;
			.menu-all {
				div:hover {
					text-decoration: underline;
				}
			}
		}
	}
	@media (min-width: 500px) and (max-width: 1190px) {
		.root {
			.view {
				padding: 20px 0;
			}
		}
		.logo-container {
			width: 30%;
			max-width: 200px;
			img{
				width: 100%;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}
		.menu-icon, .close-icon {
			display: none;
		}
  }
	@media (max-width: 500px) {
		.root {
			.view {
				padding: 0;
			}
		}
		.logo-container {
			padding: 10px 50px;
			width: 100px;
			img{
				width: 100%;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}
		.menu-all {
			display: none;
		}
		.menu-icon, .close-icon {
			display: block;
		}
  }
	@media (min-width: 1190px) {
		.root {
			width: 1190px;
			height: 130px;
			box-sizing: border-box;
			.view {
				box-sizing: border-box;
				width: 1190px;
				height: 130px;
				padding: 20px 0;
				z-index: 100;
			}
		}
		.logo-container {
			width: 200px;
			img{
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}
    .menu-icon, .close-icon {
			display: none;
		}
  }
</style>
