const t = {};
t.add = 'Add';
t.picoCare = 'PICO Care'
t.input_confirm = 'Confirm'
t.input_cancel = 'Cancel'
t.SKU = 'SKU'
t.barcode = 'Barcode'

// 底部
t.bottompic = {};
t.bottompic.aboutUs = 'About Us';
t.bottompic.aboutPICO = 'About PICO';
t.bottompic.helpCenter = "Help Center";
t.bottompic.warrantyPolicy = "Warranty Policy";
t.bottompic.privacyPolicy = 'Privacy Policy';
t.bottompic.followUs = "Follow Us";

// 顶部1
t.toolbar = {}
t.toolbar.loginOrSignIn = 'Log in / Sign up'
t.toolbar.logout = 'Sign out'
t.toolbar.changePasswd = 'Change Password'
t.toolbar.accountnum = 'Account Number'
t.toolbar.oldpassword = 'Old Password'
t.toolbar.newpassword = 'New Password'
t.toolbar.confirmpassword = 'Confirm Password'
t.toolbar.pleaseenter = 'Please enter'

// 顶部2
t.topbar = {}
t.topbar.home = 'Home'
t.topbar.registerProductWarranty = 'Register product warranty'
t.topbar.checkProductWarranty = 'Check product warranty'
t.topbar.contact = 'Contact us'

// 联系我们
t.contact = {}
t.contact.follow = 'Follow us'

// 产品
t.product = {}
t.product.list = 'My Product List'
t.product.add = 'Add'
t.product.detail = 'Product Details'
t.product.search = 'Search product or SKU number'
t.product.eng = 'User manuals in English'
t.product.thai = 'User manuals in Thai'
t.product.video = 'Video Instruction'
t.product.tip1 = 'This product is not available.  Please search for other product'
t.product.tip2 = 'Product list is empty.'
t.product.registration ='Product registration'
t.product.sku = 'SKU code'
t.product.name = 'Product name'
t.product.serialNumber = 'Serial number:'
t.product.address = 'Where did you purchase this product?'
t.product.purchaseDate = 'Received Date'
t.product.expirtyDate = 'Warranty expiry date'
t.product.registrationDate = 'Registration date'
t.product.upload = 'Upload receipt'
t.product.uploadTip = 'Drag & drop file here ...'
t.product.shopee = 'Shopee'
t.product.lazada = 'Lazada'
t.product.appShop = 'Rabbit Selection App/Shop'
t.product.facebook = 'Facebook'
t.product.tiktok = 'Tiktok'
t.product.shop = 'Shop'
t.product.other = 'Other'
t.product.gift = 'Received as a gift'

t.product.provide = 'Please provide '
t.product.SHOPEE = 'shop name'
t.product.LAZADA = 'shop name'
t.product.FACEBOOK = 'page name'
t.product.TIKTOK = 'shop name'
t.product.SHOP = 'shop name'
t.product.OTHER = 'more details'

t.product.provide_SHOPEE = 'Please provide shop name'
t.product.provide_LAZADA = 'Please provide shop name'
t.product.provide_FACEBOOK = 'Please provide page name'
t.product.provide_TIKTOK = 'Please provide shop name'
t.product.provide_SHOP = 'Please provide shop name'
t.product.provide_OTHER = 'Please provide more details'

t.product.search = 'Search'
t.product.success = 'Success'

t.pico = {}
t.pico.goto = 'Check my product warranty'
t.pico.gotoRegister = 'Register product warranty'
t.pico.registration ='Pico Warranty Registration'
t.pico.firstname = 'First Name:'
t.pico.firstnameTip = 'Please enter your first name'
t.pico.lastname = 'Last Name:'
t.pico.lastnameTip = 'Please enter your last name'
t.pico.email = 'Email Address:'
t.pico.emailTip = 'Please enter your email address'
t.pico.reEmail = 'Re-enter Email Address:'
t.pico.contactnum = 'Contact Number:'
t.pico.contactnumTip = 'Please enter your contact phone number'
t.pico.name = 'Product Name:'
t.pico.nameTip = 'Please select your purchase location'
t.pico.serialNumber = 'Device Serial Number:'
t.pico.serialNumberTip = '17 characters are required'
t.pico.purchaseDate = 'Purchase Date:'
t.pico.purchaseDateTip1 = "If input purchase date is incorrect, "
t.pico.purchaseDateTip2 = "future warranty claim will be affected"
t.pico.purchaseFrom = 'Purchased From:'
t.pico.purchaseFromTip = 'Please select your purchase location'
t.pico.online = 'Lazada/Shopee/-Central Online'
t.pico.store = 'PICO Retail Store'
t.pico.com7orBanana = 'COM7/BANANA'
t.pico.tgPhone = 'TG PHONE'
t.pico.jaymart = 'JAYMART'
t.pico.life = 'Cooperwired/.Life'
t.pico.lakecom = 'LAKECOM'
t.pico.nava = 'NAVA ASIA'
t.pico.orderNum = 'Invoice/Online Order Number:'
t.pico.orderNumTip = 'Please enter your online order number'
t.pico.uploadProof = 'Upload Proof of Purchase:'
t.pico.uploadProofTip1 = "Please attach jpg, png or PDF (max. size 1MB);"
t.pico.uploadProofTip2 = " requires clear invoice no/date for any warranty claim"
t.pico.uploadTip = 'Drag & drop file here ...'
t.pico.selectFile = 'Select file...'
t.pico.browse = 'Browse ...'


t.productList = {}
t.productList.title = 'My Product List',
t.productList.detail = 'Product Details',
t.productList.purchaseDate = 'Purchase Date',
t.productList.warrantyData = 'Warranty expiry date',
t.productList.registerData = 'Registration Date'

// 登录
t.login = {}
t.login.title = 'Login to your account'
t.login.email = 'Email'
t.login.phone = 'Phone'
t.login.getCode = 'Get Code'
t.login.code = 'Verification Code'
t.login.password = 'Password'
t.login.registerTip1 = 'Not a member yet? '
t.login.registerTip2 = 'Register now'
t.login.registerTip3 = `Didn't receive ? Send again`
t.login.loginTip1 = 'I have read and fully accept the '
t.login.loginTip2 = 'Terms and Conditions'
t.login.loginTip3 = ' and '
t.login.loginTip4 = 'Privacy Policy.';
t.login.submit = 'Submit';

// 注册
t.register = {}
t.register.accountInfo = 'Account Information'
t.register.username = 'User Name'
t.register.password = 'Password'
t.register.confirmPassword = 'Confirm Password'
t.register.personalInfo = 'Personal Information'
t.register.firstname = 'First Name'
t.register.lastname = 'Last Name'
t.register.nickname = 'Nick Name (optional)'
t.register.gender = 'Gender'
t.register.gender_Female = 'Female'
t.register.gender_Male = 'Male'
t.register.gender_Secret = 'Perfer not to say'
t.register.birth = 'Date of brith'
t.register.mobile = 'Mobile Number'
t.register.email = 'Email'
t.register.address = 'Address'
t.register.province = 'Province'
t.register.district = 'District'
t.register.postalCode = 'Postal Code'
t.register.detailAddress = 'Detail Address'
t.register.submit = 'Submit'
t.register.pleaseEnter = 'Please enter'
t.register.minNine = 'Contact number cannot be less than 9 numbers'
t.register.validateEmail = 'The email address filled in twice is different'
t.register.formatEmail = 'Please enter the correct email format'
t.register.emailFormatError = 'Email format error'
t.register.passwordDifferent = "Passwords don't match"
t.register.registerTip = 'I accept and agree to the Terms and Conditions of Services of Fanslink Communication Co., Ltd., and I have read and acknowledge the Privacy Policy of Fanslink Communication Co., Ltd.'
t.register.checkbox = 'Please tick the checkbox'

t.registerSuccess = {}
t.registerSuccess.title = 'Submitted Successfully'
t.registerSuccess.tip = 'Thank you for your interest.We will be in touch shortly.Please check your email for our follow up communication.'
t.registerSuccess.home = 'Return to Homepage'

export default t