<template>
	<div class="contact-box">
		<div class="product-advert">
			<!-- <img :src="image?image:'/images/banner5.png'" width="100%"/> -->
			<advert type="CONTACT" width="100%" height="auto"></advert>
		</div>
		<div class="contact-box">
			<h4 class="f-start mt5 mb0">{{ $t('topbar.contact') }}</h4>
			<div class="fx-list">
				<div class="fy f-grow">
					<div class="fx-list">
						<img class="icon" src="/images/line-icon.png" />
						<span class="font-xl font-bold">LINE OA</span>
					</div>
					<span class="mt4 mb8">PICO-XR-Thailand LINE Official Account</span>
					<a href="https://manager.line.biz/account/@396gahlf" target="_blank">
						<el-button class="followBtn" type="primary">{{ $t('contact.follow') }}</el-button>
					</a>
				</div>
				<div class="qr">
					<img class="p2" src="/images/line-qr.png" />
				</div>
			</div>
			<div class="fx-list">
				<div class="fy f-grow">
					<div class="fx-list">
						<img class="icon" src="/images/tiktok-icon.png" />
						<span class="font-xl font-bold">TikTok</span>
					</div>
					<span class="mt4 mb8">PICO XR TH</span>
					<a href="https://www.tiktok.com/@picoxr_th" target="_blank">
						<el-button class="followBtn" type="primary">{{ $t('contact.follow') }}</el-button>
					</a>
				</div>
				<div class="qr">
					<img class="p2" src="/images/tiktok-qr.png" />
				</div>
			</div>
			<div class="fx-list">
				<div class="fy f-grow">
					<div class="fx-list">
						<img class="icon" src="/images/facebook-icon.png" />
						<span class="font-xl font-bold">Facebook</span>
					</div>
					<span class="mt4 mb8">PICO XR Official Thailand Community</span>
					<a href="https://www.facebook.com/groups/660041976135970" target="_blank">
						<el-button class="followBtn" type="primary">{{ $t('contact.follow') }}</el-button>
					</a>
				</div>
				<div class="qr">
					<img class="p2" src="/images/facebook-qr.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import advert from '@/components/advert';
	export default {
		name: 'contact',
		components: { advert },
		data() {
			return {
				tableData: [],
				mobile: '',
			};
		},
		methods: {},
	};
</script>

<style lang="less" scoped>
	.contact-box {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		row-gap: 100px;
		margin-bottom: 140px;
		padding: 0 10px;
		width: 100%;
		max-width: 1190px;
		box-sizing: border-box;
	}
	.followBtn {
		border-radius: 1000px !important;
		width: calc(200px + 2vw);
	}
	.icon {
		width: 40px;
		height: 40px;
		margin-right: 20px;
	}

	.qr {
		flex-basis: 60%;
		img {
			width: 160px;
			height: 160px;
		}
	}

	@media (max-width: 500px) {
		.followBtn {
			width: calc(80px + 10vw);
		}
		.qr {
			margin-right: 0;
		}
	}
</style>
