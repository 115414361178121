import router from './router'
// import store from './store'
// import { Message } from 'element-ui'
import {cookieGet} from '@/common/cookie'

const whiteList = [
        '/login','/','/register','/menu','/registerSuccess'
    ] // 不重定向白名单
router.beforeEach((to, from, next) => {
	document.body.scrollTop = document.documentElement.scrollTop = 0;
        // return next()
    if (cookieGet('token') || cookieGet('logotype')) {
        if (to.path === '/login') {
            next()
                // next({ path: '/' })
        } else {
            return next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            sessionStorage.setItem('loginNext', to.fullPath);
            next({
                name: 'login',
                params: {
                    nextUrl: to.fullPath // 将跳转的路由path记录下来，登录成功后跳转到该路由
                }
            }) // 否则全部重定向到登录页
        }
    }
})

router.afterEach(() => {
})