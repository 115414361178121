import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from './zh-CN'
import enUS from './en-US'
import thTHA from './th-THA'
import { cookieGet } from '@/common/cookie'
import zhCNLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhTWLocale from 'element-ui/lib/locale/lang/th'
import enLocale from 'element-ui/lib/locale/lang/en'

Vue.use(VueI18n)

// 注册i18n实例并引入语言文件
export const messages = {
    // 'zh-CN': {
    //     '_lang': '中文',
    //     ...zhCN,
    //     ...zhCNLocale
    // },
    'en-US': {
        '_lang': 'English',
        ...enUS,//测试用,检查写死的翻译文本
        ...enLocale
    },
    'th-THA': {
        '_lang': 'ภาษาไทย',
        ...thTHA,
        ...zhTWLocale
    }
}

export default new VueI18n({
    locale: cookieGet('language') || 'th-THA',
    messages
})
