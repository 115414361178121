<template>
	<div class="check-box">
		<div class="product-advert">
			<!-- <img :src="image?image:'/images/banner5.png'" width="100%"/> -->
			<advert type="PRODUCTLIST" width="100%" height="auto"></advert>
		</div>
		<div class="goto" @click="$router.push('/picoRegister')">
			<span class="goto-right">→</span>
			<span class="goto-text">{{ $t('pico.gotoRegister') }}</span>
		</div>
		<div class="product-table">
			<div class="font-xl font-bold form-title">{{ $t('productList.title') }}</div>
			<el-table :data="tableData" style="width: 100%; min-width: 940px" header-cell-class-name="table_header">
				<el-table-column :label="$t('productList.detail')" width="450px">
					<div slot-scope="scope" class="product-detail">
						<img
							v-if="scope.row.barCode === '6970214572096'"
							class="product-img"
							src="https://pandocare-image.pandoselection.com/upload/f1728c77de39496c8001d29906cae4f0.jpg" />
						<img
							v-else-if="scope.row.barCode === '6970214571563'"
							class="product-img"
							src="https://pandocare-image.pandoselection.com/upload/f46da6c6065a4c44ac72c76657ac092c.jpg" />
						<div class="product-desc">
							<div class="product-name">{{ scope.row.productName }}</div>
							<div class="product-small">SKU:&nbsp;{{ scope.row.skuCode }}</div>
							<div class="product-small">BarCode:&nbsp;{{ scope.row.barCode }}</div>
						</div>
					</div>
				</el-table-column>
				<el-table-column :label="$t('productList.purchaseDate')" align="center">
					<template slot-scope="scope">
						<span class="product-name">{{ parseTime(scope.row.purchaseDate) }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('productList.warrantyData')" align="center">
					<template slot-scope="scope">
						<span class="product-name">{{ parseTime(scope.row.expireDate) }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('productList.registerData')" align="center">
					<template slot-scope="scope">
						<span class="product-name">{{ parseTime(scope.row.createTime) }}</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import advert from '@/components/advert';
	import { cookieGet } from '@/common/cookie';
	export default {
		name: 'picoCheck',
		components: { advert },
		data() {
			return {
				tableData: [],
				mobile: '',
			};
		},
		computed: {
			viewWidth() {
				return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			},
		},
		mounted() {
			this.mobile = JSON.parse(cookieGet('user')).mobile;
			this.getProductList();
		},
		activated() {
			this.getProductList();
		},
		methods: {
			getProductList() {
				this.$axios({
					url: '/v1/pico/warranty/product/record/query',
					method: 'post',
					headers: { token: cookieGet('token') },
					data: {
						beginpPage: 1,
						rowSize: 100,
						// username: this.mobile
					},
				}).then((res) => {
					this.tableData = res.list;
				});
			},
			// 时间格式转换
			parseTime(timeStr) {
				const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
				const dateObj = new Date(timeStr.replace(' ', 'T'));
				const year = dateObj.getFullYear();
				const month = months[dateObj.getMonth()];
				const day = dateObj.getDate();
				const parsedTime = `${day} ${month} ${year}`;
				return parsedTime;
			},
		},
	};
</script>

<style scoped>
	@media (max-width: 500px) {
		.product-advert {
			display: none;
		}
		.product-img {
			margin: 10px 12px 10px 0px;
		}
		.goto {
			margin-left: 20px;
		}
	}
	@media (min-width: 501px) and (max-width: 649px) {
		.product-img {
			margin: 10px 12px 10px 0px;
		}
	}
	@media (min-width: 650px) and (max-width: 1189px) {
		.product-img {
			margin: 10px 12px 10px 0px;
		}
	}
	@media (min-width: 1190px) {
		.product-img {
			margin: 10px 30px 10px 22px;
		}
	}
	::v-deep .table_header {
		background-color: #202124;
		color: white;
		text-align: center;
	}
	.check-box {
		box-sizing: border-box;
		width: 100%;
		max-width: 1190px;
	}
	.form-title {
		padding: 45px 15px 30px;
	}
	.product-table {
		box-sizing: border-box;
		width: 100%;
		overflow-x: auto;
	}
	.product-detail {
		display: flex;
	}
	.product-img {
		width: 100%;
		max-width: 120px;
		height: 100px;
		object-fit: contain;
	}
	.product-desc {
		flex: 1;
		margin-top: 12px;
	}
	.product-name {
		font-weight: bold;
		color: #333333;
	}
	.product-small {
		font-size: 13px;
	}
	.goto {
		float: left;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.goto-right {
		font-weight: 600;
		color: #3f00f2;
		font-size: large;
		margin-right: 2px;
	}
	.goto-text {
		font-size: 18px;
		color: #807e7e;
		text-decoration: underline;
	}
</style>
